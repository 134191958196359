import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1639840756/2498455F-7FC0-4755-914A-2935CEA73424_rtpmo9.jpg",
        "alt": "Ximena"
      }}></img></p>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote"><strong parentName="p">{`El corazón tiene Orillas estrechas Se mide como el mar
Es poderoso—incesante Bajo Y Azul Monotonía (...)`}</strong></p>
      </blockquote>
    </blockquote>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`Emily Dickinson, Palabras como espadas`}</p>
      </blockquote>
    </blockquote>
    <p>{`Cuando vives en el centro de México, no has viajado mucho y te has encontrado con el mar dos o tres veces, la inmensidad del agua salada se vuelve una añoranza, una promesa y una parte del mundo al que puedes regresar con un poema. `}<strong parentName="p">{`El mar es el corazón del mundo`}</strong>{`, como dice Dickinson, de orillas estrechas, poderoso, bajo (o profundo), azul monótono, también la tumba marina de la poeta Alfonsina Storni.`}</p>
    <p>{`Pienso en la muerte de Alfonsina Storni; creo que no murió, que su voz, sus pies y su vida, poco a poco se hicieron espuma de mar y que su alma fue a darle vida a su poesía: Lo dice mejor Mercedes Sosa: “Dormida, Alfonsina, vestida de mar”; a mí también, como a la cantautora me gustaría saber `}<strong parentName="p">{`¿Qué poemas nuevos se fue a buscar?`}</strong>{` Pero mejor la busco en la poesía que dejó, tal vez así pueda comprender su voz antigua de viento y de sal.`}</p>
    <p>{`“Frente al mar” es un poema de largo aliento en el que, así como Dickinson, Storni construye una imagen del mar como corazón: enorme, fiero, desigual y malo. Mientras que ella es blanda, podrida, débil, pobre, sin sueños y pequeña. En él, la imagen de la poeta se construye desde la fragilidad y la humildad de confesarle al Mar su aspiración de ser como él, pero la ciudad, el hombre, el entendimiento y la vulgaridad la llevaron a sucumbir en la pobreza. Sin embargo, a pesar de la condición humana de la poeta, en el verso `}<strong parentName="p">{`“Y el alma mía es como el mar, es eso,”`}</strong>{` podemos interpretar que el mar es ella misma.`}</p>
    <p>{`Una vez que se conoce el Mar y reconoce ante el Mar, queda abierta la promesa de volver. Su poder y fiereza muchas veces se convierten en la esperanza necesaria para vivir, tocar la espuma, sentir que las olas te arrastran como la vida misma, `}<strong parentName="p">{`los corazones necesitan del mar para tener la fortaleza de vivir, tal vez así la cicatriz de vivir duela menos.`}</strong></p>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote"><strong parentName="p">{`Vuele mi empeño, mi esperanza vuele... La vida mía debió ser horrible, Debió ser una arteria incontenible Y apenas es cicatriz que siempre duele.`}</strong></p>
      </blockquote>
    </blockquote>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`“Frente al mar” Alfonsina Storni`}</p>
      </blockquote>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      